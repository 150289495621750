import { useWindowSize } from '@react-hook/window-size';
import useBoundingClientRect from '@rooks/use-boundingclientrect';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { produce } from 'immer';
import React, { useEffect, useRef, useState } from 'react';
import { BasisCurve as Curve } from 'react-svg-curve';
import create from 'zustand';
import ChatMockupAmbient from '../icons/mock/chat-ambient.svg';
import ChatMockup from '../icons/mock/chat.svg';
import HintgiverLoginMockupAmbient from '../icons/mock/hintgiver-login-ambient.svg';
import HintgiverLoginMockup from '../icons/mock/hintgiver-login.svg';
import IncidentCardMockupAmbient from '../icons/mock/incident-card-ambient.svg';
import IncidentCardMockup from '../icons/mock/incident-card.svg';
import ReportFormMockupAmbient from '../icons/mock/report-form-ambient.svg';
import ReportFormMockup from '../icons/mock/report-form.svg';
import CaseworkerAvatar from '../images/mock/caseworker-avatar.png';
import HintgiverAvatar from '../images/mock/hintgiver-avatar.png';
import { ButtonRecipe } from './Button.css';
import {
	ActorCard as ActorCardStyle,
	BackgroundLine,
	Line as LineStyle,
	Mockup as MockupStyle,
	MockupAmbientStyle,
	MockupComponent,
	Outer,
	RowLeft,
	RowRight,
} from './HowItWorks.css';
import { Section } from './Layout';
import { Text, Title } from './Text';

interface PointStore {
	points: Map<number, [number, number]>;
	setPoint: (i: number, point: [number, number]) => void;
}

const usePointStore = create<PointStore>((set, get) => ({
	points: new Map(),
	setPoint: (i: number, point: [number, number]) =>
		set(
			produce((state) => {
				state.points.set(i, point);
			}),
		),
}));

const Line = () => {
	const ws = useWindowSize();
	const points = usePointStore((state) => state.points);
	const [drawPoints, setDrawPoints] = useState<[number, number][]>();
	const ref = useRef<HTMLDivElement>(null);
	const rect = useBoundingClientRect(ref);
	useEffect(() => {
		if (rect)
			setDrawPoints(
				Array.from(points.entries())
					.sort((p1, p2) => p1[0] - p2[0])
					.map(([i, [x, y]]) => [x - rect.left, y - rect.top]),
			);
	}, [rect, points, ws]);
	return (
		<div ref={ref} style={{ width: '100%', height: '100%' }}>
			{drawPoints && (
				<svg width="100%" height="100%" className={LineStyle}>
					<Curve stroke="inherit" data={drawPoints} showPoints={false} />
				</svg>
			)}
		</div>
	);
};

const PointWrapper = ({
	children,
	i,
	className,
	style,
}: {
	children: React.ReactNode;
	i: number;
	className?: string;
	style?: React.CSSProperties;
}) => {
	const ws = useWindowSize();
	const ref = useRef<HTMLDivElement>(null);
	const rect = useBoundingClientRect(ref);
	const { setPoint } = usePointStore();
	useEffect(() => {
		if (!rect) return;
		const point: [number, number] = [
			rect.left + rect.width / 2,
			rect.top + rect.height / 2,
		];
		setPoint(i, point);
	}, [rect, ws, i]);
	return (
		<div style={style} className={className} ref={ref}>
			{children}
		</div>
	);
};

const Mockup = ({
	children,
	ambient,
	side = 'right',
}: {
	children: React.ReactNode;
	side: 'right' | 'left';
	ambient?: React.ReactNode;
}) => {
	return (
		<div className={MockupStyle}>
			<div className={MockupAmbientStyle({ side })}>{ambient && ambient}</div>
			<div className={MockupComponent({ side })}>{children}</div>
		</div>
	);
};

const ActorCard = (props: {
	name: string;
	role: string;
	image: React.ReactNode;
	description: React.ReactNode;
}) => {
	return (
		<div className={ActorCardStyle}>
			<Section direction="vertical" gap="large">
				<Section direction="horizontal" justify="left" gap="medium">
					{props.image}
					<Section direction="vertical" width="auto" align="start">
						<Text strong>{props.name}</Text>
						<Text variant="secondary">{props.role}</Text>
					</Section>
				</Section>
				{props.description}
			</Section>
		</div>
	);
};

const HintgiverCard = ({ description }: { description: React.ReactNode }) => {
	const { t } = useTranslation('HowItWorks', { keyPrefix: 'Hintgiver' });
	return (
		<ActorCard
			name={t('Name')}
			role={t('Role')}
			image={<img src={HintgiverAvatar} alt={t('Avatar of Hintgiver')} />}
			description={description}
		/>
	);
};

const CaseworkerCard = ({ description }: { description: React.ReactNode }) => {
	const { t } = useTranslation('HowItWorks', { keyPrefix: 'Caseworker' });
	return (
		<ActorCard
			name={t('Name')}
			role={t('Role')}
			image={<img src={CaseworkerAvatar} alt={t('Avatar of Caseworker')} />}
			description={description}
		/>
	);
};

const Row = ({
	side,
	children,
}: {
	side: 'left' | 'right';
	children: React.ReactNode;
}) => {
	const ref = useRef<HTMLDivElement>(null);
	const [opacity, setOpacity] = useState(1.0);
	// useEffect(() => {
	// 	document.addEventListener('scroll', () => {
	// 		if (!ref.current) return;
	// 		const viewHeight = window.innerHeight;
	// 		const rect = ref.current.getBoundingClientRect();
	// 		const rectCenter = rect.top + rect.height / 2;
	// 		let dist = Math.abs((rectCenter - viewHeight / 2) / viewHeight) * 1.5;
	// 		// if (dist > 0.25) dist -= 0.25;
	// 		// else dist = 0;
	// 		setOpacity(Math.max(0, 1 - dist * dist));
	// 	});
	// }, []);
	return (
		<div ref={ref} style={{ opacity }} className={side === 'left' ? RowLeft : RowRight}>
			{children}
		</div>
	);
};

const HowTo = () => {
	const { t, i18n } = useTranslation('HowItWorks');
	return (
		<Section gap="huge" width="fill" align="center">
			<Section gap="medium">
				<Title align="center" level="section">
					{t('Title')}
				</Title>
				<Text align="center">
					<Trans ns="HowItWorks" i18nKey={'Description_html'} />
				</Text>
				<Section direction="horizontal" justify="right">
					<Link
						to="/knowledge"
						className={ButtonRecipe({ variant: 'primaryText', size: 'medium' })}
					>
						{t('Link')}
					</Link>
				</Section>
			</Section>
			<div className={Outer}>
				<Row side="left">
					<PointWrapper i={0}>
						<HintgiverCard
							description={
								<ul>
									<Trans
										ns="HowItWorks"
										i18nKey={'Steps.Submission.Bullet1_html'}
										parent="li"
									/>
									<Trans
										ns="HowItWorks"
										i18nKey={'Steps.Submission.Bullet2_html'}
										parent="li"
									/>
									<Trans
										ns="HowItWorks"
										i18nKey={'Steps.Submission.Bullet3_html'}
										parent="li"
									/>
								</ul>
							}
						/>
					</PointWrapper>
					<PointWrapper className={MockupStyle} i={1}>
						<Mockup
							side="left"
							ambient={<ReportFormMockupAmbient width="100%" height="auto" />}
						>
							<ReportFormMockup
								width="100%"
								height="auto"
								preserveAspectRatio="xMinYMid"
							/>
						</Mockup>
					</PointWrapper>
				</Row>
				<Row side="right">
					<PointWrapper i={2}>
						<CaseworkerCard
							description={
								<ul>
									<Trans
										ns="HowItWorks"
										i18nKey={'Steps.Reception.Bullet1_html'}
										parent="li"
									/>
									<Trans
										ns="HowItWorks"
										i18nKey={'Steps.Reception.Bullet2_html'}
										parent="li"
									/>
									<Trans
										ns="HowItWorks"
										i18nKey={'Steps.Reception.Bullet3_html'}
										parent="li"
									/>
								</ul>
							}
						/>
					</PointWrapper>
					<PointWrapper className={MockupStyle} i={3}>
						<Mockup
							side="right"
							ambient={<IncidentCardMockupAmbient width="100%" height="auto" />}
						>
							<IncidentCardMockup
								width="100%"
								height="auto"
								preserveAspectRatio="xMaxYMid"
							/>
						</Mockup>
					</PointWrapper>
				</Row>
				<Row side="left">
					<PointWrapper i={4}>
						<HintgiverCard
							description={
								<ul>
									<Trans
										ns="HowItWorks"
										i18nKey={'Steps.Login.Bullet1_html'}
										parent="li"
									/>
									<Trans
										ns="HowItWorks"
										i18nKey={'Steps.Login.Bullet2_html'}
										parent="li"
									/>
								</ul>
							}
						/>
					</PointWrapper>
					<PointWrapper className={MockupStyle} i={5}>
						<Mockup
							ambient={<HintgiverLoginMockupAmbient width="100%" height="auto" />}
							side="left"
						>
							<HintgiverLoginMockup
								width="100%"
								height="auto"
								preserveAspectRatio="xMinYMid"
							/>
						</Mockup>
					</PointWrapper>
				</Row>
				<Row side="right">
					<PointWrapper i={6}>
						<CaseworkerCard
							description={
								<ul>
									<Trans
										ns="HowItWorks"
										i18nKey={'Steps.Chat.Bullet1_html'}
										parent="li"
									/>
									<Trans
										ns="HowItWorks"
										i18nKey={'Steps.Chat.Bullet2_html'}
										parent="li"
									/>
								</ul>
							}
						/>
					</PointWrapper>
					<PointWrapper className={MockupStyle} i={7}>
						<Mockup
							side="right"
							ambient={<ChatMockupAmbient width="100%" height="auto" />}
						>
							{/* Note how we use xMax instead of xMin for the right side rows */}
							<ChatMockup width="100%" height="auto" preserveAspectRatio="xMaxYMid" />
						</Mockup>
					</PointWrapper>
				</Row>
				<div className={BackgroundLine}>
					<Line />
				</div>
			</div>
		</Section>
	);
};

export default HowTo;
