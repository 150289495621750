import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import { ButtonRecipe } from '../components/Button.css';
import ContentSection from '../components/ContentSection';
import CustomerLogos from '../components/CustomerLogos';
import FaqSection from '../components/FaqSection';
import Hero from '../components/Hero';
import HowTo from '../components/HowItWorks';
import Layout, { Section } from '../components/Layout';
import Link from '../components/Link';
import { Seo } from '../components/Seo';
import TestimonialSection from '../components/Testimonial';
import ContactUsSvg from '../images/contact-us-amico.svg';
import MentionUsSvg from '../images/Mention-amico-1.svg';
import CourtRightsSvg from '../images/software-business/first-visual-court-rights.svg';
import KeyGuySoftwareMockupSvg from '../images/software-business/fourth-visual-key-guy-software-mockup.svg';
import HeroSvg from '../images/software-business/hero.svg';
import RollingBallSoftwareMockupSvg from '../images/software-business/second-visual-rolling-ball-software-mockup.svg';
import MailGuySoftwareMockupSvg from '../images/software-business/third-visual-mail-guy-software-mockup.svg';
import { sprinkles } from '../sprinkles.css';

// markup
const SoftwarePage = ({
	data: { allTestimonialsJson, allFaqJson },
}: {
	data: {
		allTestimonialsJson: { nodes: Queries.TestimonialsJson[] };
		allFaqJson: { nodes: Queries.FaqJson[] };
	};
}) => {
	const {
		t,
		i18n: { language },
	} = useTranslation('SoftwarePage');
	if (language === 'de') {
		return (
			<Layout padding={false} limitWidth={false} title={t('Meta.Title')}>
				<Section direction="vertical" width="fill" gap="giant">
					<Hero
						width="wide"
						title="Ihr Whistleblower Software Partner"
						text="Sind Sie in Ihrem Unternehmen ein Entscheidungsträger oder Spezialist für Compliance-Fragen und wollen Hinweisgeber-Management-Software einführen?"
						actions={[
							<Link
								key={0}
								to="https://my.meetergo.com/martinmeng/hinschg"
								className={ButtonRecipe({ variant: 'primary', size: 'medium' })}
							>
								Jetzt Demotermin vereinbaren
							</Link>,
						]}
						image={
							<img
								src={HeroSvg}
								alt="konfidal-slogan 'simple. fast. compliant.' in einem belebten Büro."
								width="100%"
								height="auto"
								className={sprinkles({
									width: 'fill',
									minWidth: {
										mobile: 'three',
										tablet: 'fourhalf',
									},
								})}
							/>
						}
					/>
					<ContentSection
						side="right"
						background="blue"
						title={{
							__html:
								'Bereiten Sie sich optimal auf die Umsetzung des HinSchG in Ihrem Unternehmen vor, indem Sie einen sicheren Meldekanal für Ihre Mitarbeitenden bereitstellen und Ihren Meldestellenverantwortlichen hervorragende Software an die Hand geben! Made and hosted in Germany.',
						}}
						titleLevel="paragraph"
						imageAlt="Gegenstände eines Gerichtssaals"
						imageSrc={CourtRightsSvg}
					/>
					<ContentSection
						side="left"
						imageAlt="Rollender Ball"
						imageSrc={RollingBallSoftwareMockupSvg}
						title={{
							__html:
								'Mit der konfidal Whistleblower-Software stellen Sie sicher, dass Ihr Unternehmen <strong>alle rechtlichen Anforderungen erfüllt</strong> und eine effektive Lösung für&nbsp;die&nbsp;Verwaltung von Whistleblower-Fällen bietet.',
						}}
						titleLevel="paragraph"
						text={{
							__html:
								'Schaffen Sie Vertrauen und Sicherheit für Ihre Mitarbeiter, indem Sie Meldungen und Hinweise anonym und effizient übermitteln und bearbeiten können.',
						}}
						cta1Text=""
						cta1Link="https://my.meetergo.com/martinmeng/hinschg"
					/>
					<CustomerLogos />
					<ContentSection
						side="right"
						title={{
							__html:
								'Die <strong>konfidal</strong> Whistleblower-Software bietet ein&nbsp;ausgeklügeltes System, um den gesetzlich vorgeschriebenen internen Meldeweg effizient und benutzerfreundlich zu gestalten.',
						}}
						titleLevel="paragraph"
						text={{
							__html:
								'Schaffen Sie Vertrauen und Sicherheit für Ihre Mitarbeitenden, indem Sie Meldungen und Hinweise anonym und effizient entgegennehmen und bearbeiten.',
						}}
						cta1Text=""
						cta1Link="https://my.meetergo.com/martinmeng/hinschg"
						cta2Text=""
						cta2Link="/features"
						imageAlt="Mockup der konfidal Software"
						imageSrc={MailGuySoftwareMockupSvg}
					/>
					<ContentSection
						side="left"
						title={{
							__html:
								'Verlassen Sie sich auf das konfidal Hinweisgebersystem, um&nbsp;ein hohes Maß an Anonymität und Sicherheit für Hinweisgeber in Ihrem Unternehmen zu gewährleisten.',
						}}
						titleLevel="paragraph"
						text={{
							__html:
								'Die intuitive und schnelle Software ermöglicht eine effiziente Bearbeitung von Berichten und Hinweisen und unterstützt Sie beim Aufbau von Rechtskonformität und Vertrauen in Ihrem Unternehmen.',
						}}
						cta1Text="Jetzt Demo vereinbaren"
						cta1Link="https://my.meetergo.com/martinmeng/hinschg"
						cta2Text="Funktionen im Überblick"
						cta2Link="/features"
						imageAlt="Modell des Vorfall-Chats des konfidal Admin-Portals."
						imageSrc={KeyGuySoftwareMockupSvg}
					/>
					<ContentSection
						side="left"
						background="blue"
						title={{
							__html:
								'Warten Sie nicht länger und rüsten Sie Ihr Unternehmen optimal für die Anforderungen des Hinweisgeberschutzgesetzes',
						}}
						titleLevel="paragraph"
						text={{
							__html:
								'Entscheiden Sie sich jetzt für die konfidal Whistleblower Software und profitieren Sie von einer effizienten, benutzerfreundlichen und rechtssicheren Lösung, die Meldungen, Hinweise und Informationen sicher und (falls gewünscht) anonym entgegennimmt und verarbeitet. Kontaktieren Sie uns noch heute für eine unverbindliche Produktdemonstration oder eine Beratung zu unserem Angebot.',
						}}
						cta1Text="Termin wählen"
						cta1Link="https://my.meetergo.com/martinmeng/hinschg"
						imageAlt="Ein Kundenbetreuer mit einem Headset, das in einem Smartphone abgebildet ist."
						imageSrc={ContactUsSvg}
					/>
					<Section width="wide" padding>
						<HowTo />
					</Section>
					{allTestimonialsJson?.nodes && (
						<TestimonialSection testimonials={allTestimonialsJson?.nodes} />
					)}
					{/* TODO: Five advantages at a glance
			Need to build vertical tabbed slider comoponent first */}
					<ContentSection
						background="blue"
						side="right"
						title="Sie sind sich noch nicht sicher oder suchen noch weitere gute Gründe für die konfidal Whistleblower Software? Kein Problem. Unser Sales-Team freut sich auf Sie!"
						titleLevel="subsection"
						cta1Text="Gespräch terminieren"
						cta1Link="https://my.meetergo.com/martinmeng/hinschg"
						imageSrc={MentionUsSvg}
						imageAlt="Bild einer Person auf einem Display."
					/>
					{allFaqJson?.nodes.length && (
						<FaqSection
							content={allFaqJson.nodes[0].entries.filter((e) =>
								e.pages.includes('/software-business'),
							)}
							width="wide"
						/>
					)}
				</Section>
			</Layout>
		);
	}
	// english
	else {
		return (
			<Layout padding={false} limitWidth={false} title={t('Meta.Title')}>
				<Section direction="vertical" width="fill" gap="giant">
					<Hero
						width="wide"
						title="Your Whistleblowing Success Partner"
						text="Are you a decision-maker or specialist for compliance issues in your company?"
						actions={[
							<Link
								key={0}
								to="https://my.meetergo.com/martinmeng/hinschg"
								className={ButtonRecipe({ variant: 'primary', size: 'medium' })}
							>
								Get a demo of our software
							</Link>,
						]}
						image={
							<img
								src={HeroSvg}
								alt="konfidal-slogan 'fast simple compliant' surround an employee in an office."
								width="100%"
								height="auto"
								className={sprinkles({
									width: 'fill',
									minWidth: {
										mobile: 'three',
										tablet: 'fourhalf',
									},
								})}
							/>
						}
					/>
					<ContentSection
						side="right"
						background="blue"
						title={{
							__html:
								'Prepare optimally for the implementation of the EU Whistleblower Directive or implement already applicable law if you live in a member state of the EU where whistleblower protection laws are already in force!',
						}}
						titleLevel="paragraph"
						imageAlt="Courtroom utilities"
						imageSrc={CourtRightsSvg}
					/>
					<ContentSection
						side="left"
						imageAlt="Rolling ball software"
						imageSrc={RollingBallSoftwareMockupSvg}
						title={{
							__html:
								'With the konfidal whistleblower software, you ensure that your company meets all legal requirements and provides an effective solution for&nbsp;the&nbsp;management of whistleblower cases.',
						}}
						titleLevel="paragraph"
						text={{
							__html:
								'Create trust and security for your employees by receiving and processing reports and tips anonymously and efficiently.',
						}}
						cta1Text="Get a demo"
						cta1Link="https://my.meetergo.com/martinmeng/hinschg"
						cta2Text="Software features"
						cta2Link="/features"
					/>
					<CustomerLogos />
					<ContentSection
						side="right"
						title={{
							__html:
								'The <strong>konfidal</strong> whistleblower software offers a&nbsp;sophisticated system to make the legally required internal reporting channel efficient and user&nbsp;-&nbsp;friendly.',
						}}
						titleLevel="paragraph"
						text={{
							__html:
								'Create trust and security for your employees by receiving and processing reports and tips anonymously and efficiently.',
						}}
						cta1Text="Get a demo"
						cta1Link="https://my.meetergo.com/martinmeng/hinschg"
						imageAlt="Mockup of the kofidal reporting portal."
						imageSrc={MailGuySoftwareMockupSvg}
					/>
					<ContentSection
						side="left"
						title={{
							__html:
								'Rely on the konfidal whistleblower system to&nbsp;ensure a high level of anonymity and security for whistleblowers in your company.',
						}}
						titleLevel="paragraph"
						text={{
							__html:
								'The intuitive and fast software enables efficient processing of reports and tips and supports you in building legal compliance and trust in your company.',
						}}
						cta1Text="Get a demo"
						cta1Link="https://my.meetergo.com/martinmeng/hinschg"
						cta2Text="Software features"
						cta2Link="/features"
						imageAlt="Mockup of the konfidal admin portal incident chat."
						imageSrc={KeyGuySoftwareMockupSvg}
					/>
					<ContentSection
						side="left"
						background="blue"
						title={{
							__html:
								'Don&apos;t wait any longer and equip your company optimally for the requirements of the Whistleblower Protection Act!',
						}}
						titleLevel="paragraph"
						text={{
							__html:
								'Choose <strong>konfidal Whistleblower Software</strong> now and benefit from an&nbsp;efficient, user-friendly and legally compliant solution that receives and processes reports, tips and information securely and (if desired) anonymously. Contact us today for a no-obligation product demonstration or consultation on our offering. We look forward to&nbsp;helping you implement it!',
						}}
						cta1Text="Contact us"
						cta1Link="https://my.meetergo.com/martinmeng/hinschg"
						imageAlt="A customer service representative with a headset depicted inside a smartphone."
						imageSrc={ContactUsSvg}
					/>
					{allTestimonialsJson && (
						<TestimonialSection
							title="References"
							text="konfidal makes life easy"
							actions={[
								<Link key={0} to="https://my.meetergo.com/martinmeng/hinschg">
									Book consultation
								</Link>,
							]}
							testimonials={allTestimonialsJson?.nodes}
						/>
					)}
					<Section width="wide" padding>
						<HowTo />
					</Section>
					{/* TODO: Five advantages at a glance
			Need to build vertical tabbed slider comoponent first */}
					<ContentSection
						background="blue"
						side="right"
						title="Want more good reasons?"
						titleLevel="subsection"
						cta1Text="Make an appointment now"
						cta1Link="https://my.meetergo.com/martinmeng/hinschg"
						imageSrc={MentionUsSvg}
						imageAlt="TODO"
					/>
					{allFaqJson?.nodes?.length && (
						<FaqSection
							content={allFaqJson?.nodes[0].entries.filter((e) =>
								e.pages.includes('/software-business'),
							)}
							width="wide"
						/>
					)}
				</Section>
			</Layout>
		);
	}
};
export default SoftwarePage;

export const Head = () => {
	return <Seo title="konfidal Whistleblowing Software" />;
};

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		allTestimonialsJson(
			filter: { language: { eq: $language }, slug: { in: ["o-group"] } }
		) {
			nodes {
				name
				company
				companyLogo {
					childImageSharp {
						gatsbyImageData(height: 50)
					}
				}
				personImage {
					childImageSharp {
						gatsbyImageData(layout: FULL_WIDTH)
					}
				}
				text
				personImageAlt
				companyLogoAlt
				link
			}
		}
		allFaqJson(filter: { language: { eq: $language } }) {
			nodes {
				entries {
					title
					content
					pages
				}
			}
		}
	}
`;

// export const Head = (props: HeadProps) => <SEO />;
